<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-3 has-text-centered mb-2">
        {{ title }}
      </h2>
      <div class="event-contact-information">
        <event-contact-information-button v-if="emailVisible" :link="emailLink">
          <template #icon>
            <mail-icon class="svg-icon"></mail-icon>
          </template>
          <template>
            <p class="event-contact-information-title">{{ $t("common.contact_email") }}</p>
            <p class="event-contact-information-subtitle">
              {{ email }}
            </p>
          </template>
        </event-contact-information-button>
        <event-contact-information-button v-if="phoneVisible" :link="phoneLink">
          <template #icon>
            <phone-icon class="svg-icon"></phone-icon>
          </template>
          <template>
            <p class="event-contact-information-title">{{ $t("common.concact_phone") }}</p>
            <p class="event-contact-information-subtitle">
              {{ phone }}
            </p>
          </template>
        </event-contact-information-button>
        <event-contact-information-button v-if="websiteVisible" :link="websiteLink">
          <template #icon>
            <website-icon-transparent class="svg-icon" v-if="isUpgrade2024Event"></website-icon-transparent>
            <website-icon class="svg-icon" v-else></website-icon>
          </template>
          <template>
            <p class="event-contact-information-title">{{ $t("common.contact_website") }}</p>
            <p class="event-contact-information-subtitle">
              {{ websiteLink }}
            </p>
          </template>
        </event-contact-information-button>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import EventContactInformationButton from "@/web/components/contactinformations/EventContactInformationButton";
import MailIcon from "@/assets/common/icon_mail.svg";
import PhoneIcon from "@/assets/common/icon_phone.svg";
import WebsiteIcon from "@/assets/common/icon_website.svg";
import WebsiteIconTransparent from "@/assets/common/icon_website2.svg";

export default {
  name: "EventContactInfoComponent",
  mixins: [LpConfigMixin],
  components: { EventContactInformationButton, Separator, MailIcon, PhoneIcon, WebsiteIcon, WebsiteIconTransparent },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),

    isUpgrade2024Event() {
      return this.event.id === 1530;
    },

    moduleFields: () => LpConfigConstants.EVENT_CONTACT_INFO_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    emailVisible() {
      return this.emailLink && this.getFieldValueOrDefault(this.moduleData, this.moduleFields.EMAIL_VISIBLE, true);
    },

    email() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.CUSTOM_EMAIL, this.event.email);
    },

    emailLink() {
      return this.email && `mailto:${this.email}`;
    },

    phoneVisible() {
      return this.phoneLink && this.getFieldValueOrDefault(this.moduleData, this.moduleFields.PHONE_VISIBLE, true);
    },

    phone() {
      let defaultValue = this.event.phone_country_code && this.event.phone && `${this.event.phone_country_code}${this.event.phone}`;
      return this.getCustomTranslation(this.moduleData, this.moduleFields.CUSTOM_PHONE, defaultValue);
    },

    phoneLink() {
      return this.phone && `tel:${this.phone}`;
    },

    websiteVisible() {
      return this.websiteLink && this.getFieldValueOrDefault(this.moduleData, this.moduleFields.WEBSITE_VISIBLE, true);
    },

    websiteLink() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.CUSTOM_WWW, this.event.website);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
