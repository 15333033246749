<template>
  <div @click="openDetailsModal" class="box exhibitor-container is-borderless has-background-primary px-2 py-2" :style="backgroundStyle">
    <div class="media is-vcentered mb-2">
      <div class="media-left mr-2">
        <div class="box is-borderless is-clipped has-logo px-0 py-0">
          <figure class="image is-centered is-60x60">
            <img class="has-image-centered" :src="logoUrl" />
          </figure>
        </div>
      </div>

      <div class="media-content">
        <h2 class="title is-6 has-text-white">{{ title }}</h2>
        <p class="subtitle is-7 has-text-white">{{ subtitle }}</p>
      </div>
    </div>

    <div v-if="tags.length" class="exhibitor-details-tags-list with-wrap mb-1">
      <div v-for="tag in tags" @click.stop="$emit('tag-click', tag)" :key="`exh-${exhibitor.id}-${tag.id}`">
        <tag-content :color="tag.hex" :clickable="true">
          <template>
            {{ tag.value.toUpperCase() }}
          </template>
        </tag-content>
      </div>
    </div>

    <!--    <div class="content has-text-white is-size-7">-->
    <!--      <div class="description-text">-->
    <!--        {{ description }}-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import TagContent from "@/widgets/shared/components/TagContent";
import { mapMutations } from "vuex";
import { WidgetViewTypes } from "@/widgets/shared/store/modules/widget-view";

export default {
  name: "ExhibitorListItemView",

  props: ["data", "exhibitors", "lpMode", "isAuthorized", "inboxEnabled"],
  components: {
    TagContent,
  },
  computed: {
    exhibitor() {
      return this.data;
    },

    logoUrl() {
      const picture = this.exhibitor.picture;
      if (picture) {
        return picture && picture.file_thumbnail_200_url;
      } else {
        return require("@/assets/photo_placeholder.png");
      }
    },

    title() {
      return this.exhibitor.name;
    },

    subtitle() {
      return this.exhibitor.scope_description;
    },

    description() {
      return this.exhibitor.description;
    },

    tags() {
      return this.exhibitor.tags_exhibitors && this.exhibitor.tags_exhibitors.slice(0, 3);
    },

    backgroundStyle() {
      if (this.data.vip_picture) {
        const imageUrl = this.data.vip_picture.file_thumbnail_750_url;
        const overlay = `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5))`;
        return {
          backgroundImage: `${overlay}, url("${imageUrl}")`,
        };
      }

      return null;
    },
  },

  methods: {
    ...mapMutations("widgetView", ["changeActiveView"]),
    
    openDetailsModal() {
      if (this.lpMode) {
        this.$root.openExhibitorModal({
          exhibitorId: this.exhibitor.id,
          exhibitors: this.exhibitors,
          isAuthorized: this.isAuthorized,
          lpMode: false,
          inboxEnabled: this.inboxEnabled,
        });
      } else {
        this.changeActiveView({
          type: WidgetViewTypes.EXHIBITOR_DETAILS,
          componentId: this.$root.componentId,
          data: {
            exhibitorId: this.exhibitor.id,
            exhibitors: this.exhibitors,
            isAuthorized: this.isAuthorized,
            lpMode: false,
            inboxEnabled: this.inboxEnabled,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
}

.exhibitor-container {
  overflow: hidden;
  height: 222px;
  cursor: pointer;
}

.live-stream-icon,
.arrow-right-icon {
  vertical-align: middle;
}

.live-stream-icon {
  margin-top: -6px;
  width: 30px;
  height: 30px;
}

.description-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
