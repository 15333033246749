<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div class="field mb-3">
        <div class="control mr-2">
          <label class="radio is-size-7">
            <input type="radio" :value="0" v-model="quizzesCategory"/>
            <span> {{ $t("quizzes.category_all") }} </span>
          </label>
        </div>
        <div class="control mr-2">
          <label class="radio is-size-7">
            <input type="radio" :value="1" v-model="quizzesCategory"/>
            <span> {{ $t("quizzes.category_completed") }} </span>
          </label>
        </div>
        <div class="control mr-2">
          <label class="radio is-size-7">
            <input type="radio" :value="2" v-model="quizzesCategory"/>
            <span> {{ $t("quizzes.category_uncompleted") }} </span>
          </label>
        </div>
      </div>
      <div v-if="filteredQuizzes.length">
        <quizzes-pager-view :quizzes="filteredQuizzes" :page-size="pageSize"></quizzes-pager-view>
      </div>
      <div v-else class="placeholder-container has-text-secondary">
        {{ placeholderText }}
      </div>
    </div>
  </article>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Constants from "@/web/constants";
import QuizzesPagerView from "@/web/components/quizzes/QuizzesPagerView";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "QuizzesComponent",
  mixins: [LpConfigMixin],
  components: { QuizzesPagerView, Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      quizzesCategory: QUIZ_CATEGORIES.ALL,
    };
  },

  mounted() {
    this.loadQuizzes(this.componentId);
  },

  computed: {
    ...mapGetters("quizzes", ["getAllFromComponent"]),
    ...mapGetters("components", ["getComponentById"]),

    Constants: () => Constants,
    quizzesModuleFields: () => LpConfigConstants.QUIZZES_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    componentId() {
      return this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.COMPONENT_ID, null);
    },

    quizComponent() {
      return this.getComponentById(this.componentId);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, this.quizComponent.label);
    },

    pageSize() {
      return this.getFieldValueOrDefault(this.moduleData, this.quizzesModuleFields.PAGE_SIZE, 6);
    },

    quizzes() {
      return this.getAllFromComponent(this.componentId);
    },

    filteredQuizzes() {
      if (this.quizzesCategory === 0) {
        return this.quizzes;
      } else if (this.quizzesCategory === 1) {
        return this.quizzes.filter(quiz => quiz.is_completed_by_me);
      } else {
        return this.quizzes.filter(quiz => !quiz.is_completed_by_me);
      }
    },

    placeholderText() {
      if (this.quizzesCategory === QUIZ_CATEGORIES.COMPLETED) {
        return this.$t("quizzes.quiz_completed_placeholder_text");
      } else {
        return this.$t("quizzes.quiz_uncompleted_placeholder_text");
      }
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes"]),
  },
};

const QUIZ_CATEGORIES = {
  ALL: 0,
  COMPLETED: 1,
  UNCOMPLETED: 2,
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.placeholder-container {
  width: 100%;
  height: 300px;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
