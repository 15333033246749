<template>
  <div class="event-introduction" :class="introductionClasses" v-if="isNotEfniEvent">
    <!-- Timer and general event info -->
    <event-info-component id="home" v-if="eventInfoVisible"></event-info-component>

    <!-- Landing page modules -->
    <template v-for="({ component, moduleData }) in mappedModules">
      <component :key="moduleData.order" :is="component" :module-data="moduleData"
                 :id="`module-${moduleData.order}`"></component>
    </template>

    <img class="pism-planet-overlay" :src="pismOverlayImg" v-if="isPismEvent"/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import EventInfoComponent from "@/web/components/EventInfoComponent";
import { navScrollTo } from "@/shared/utils";
import lpModules from "./modules";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import PismPlanetOverlay from "@/assets/custom/pism/pism_planet_overlay.png";

export default {
  name: "Introduction",

  components: {
    EventInfoComponent,
    // Merge with all supported lp module components
    // Required to allow <component> tag to render the modules
    ...mapModules(lpModules)
  },

  mounted() {
    navScrollTo(this.$route.hash);
  },

  computed: {
    ...mapGetters(["visibleModules", "sideMenuType"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("sideMenu", ["isSideMenuPanelOpen", "isSideMenuPanelSneakPeakMode"]),
    ...mapState(["eventId"]),

    isNotEfniEvent() {
      return this.eventId !== 1185;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isDolbyEvent() {
      return this.eventId === 1318;
    },

    pismOverlayImg() {
      return PismPlanetOverlay;
    },

    mappedModules() {
      return this.visibleModules.map(module => ({
        component: lpModules[module.type],
        moduleData: module
      }));
    },

    isSideMenuBubbles() {
      return this.sideMenuType === LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
    },

    introductionClasses() {
      return {
        "has-side-menu-panel": !this.isSideMenuBubbles && this.isAuthorized,
        "side-menu-panel-opened": this.isSideMenuPanelOpen,
        "is-sneak-peak": this.isSideMenuPanelSneakPeakMode,
      };
    },

    eventInfoVisible() {
      //custom change: google cloud events
      return !(this.eventId === 298 || this.eventId === 299 ||  this.eventId === 1399 || this.eventId === 1623);
    },
  },
};

function mapModules(modules) {
  // eslint-disable-next-line prettier/prettier
  return Object.values(modules).reduce(
    (acc, module) => ({ ...acc, [module.name]: module }), {}
  );
}
</script>
