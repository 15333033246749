<template>
  <paginated-content :items="quizzes" :perPage="pageSize">
    <template v-slot="{ displayItems }">
      <div class="columns">
        <div v-for="(quiz, index) in displayItems" :key="index" class="column is-half">
          <quiz-item :quiz="quiz"></quiz-item>
        </div>
      </div>
    </template>
  </paginated-content>
</template>

<script>
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import QuizItem from "@/web/components/quizzes/QuizItem";

export default {
  name: "QuizzesPagerView",
  components: {PaginatedContent, QuizItem},
  props: ["quizzes", "pageSize"],
};
</script>

<style lang="scss" scoped>
.columns {
  flex-wrap: wrap;
}
</style>
