<template>
  <b-button class="button event-contact-information-button" type="is-outlined" @click="openLink" :class="{ 'upgrade-2024': isUpgrade2024Event }">
    <div class="event-contact-information-inner-container">
      <div class="event-contact-information-icon-container">
        <slot name="icon"></slot>
      </div>
      <div class="event-contact-information-text-container">
        <slot></slot>
      </div>
    </div>
  </b-button>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "EventContactInformationButton",
  props: {
    link: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),
    isUpgrade2024Event() {
      return this.event.id === 1530;
    },
  },

  methods: {
    openLink() {
      window.open(this.link, "_blank");
    }
  }
};
</script>

<style scoped>
.upgrade-2024 {
  background-image: url(~@/assets/custom/upgrade_www_banner.jpg) !important;
  color: #fff !important;
}
</style>
