<template>
  <article class="module">
    <div class="container has-text-centered">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>

      <button class="button is-primary px-5 py-3" @click="onButtonClick">
        {{ buttonText }}
      </button>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "ButtonPopupComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buttonPopupModuleFields: () => LpConfigConstants.BUTTON_POPUP_MODULE_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    buttonText() {
      return this.getCustomTranslation(this.moduleData, this.buttonPopupModuleFields.BUTTON_TEXT);
    },

    popupTitle() {
      return this.getCustomTranslation(this.moduleData, this.buttonPopupModuleFields.POPUP_TITLE);
    },

    popupTextAlignment() {
      return this.getFieldValueOrDefault(this.moduleData, this.buttonPopupModuleFields.POPUP_TEXT_ALIGNMENT, LpConfigConstants.POPUP_TEXT_ALIGNMENT_OPTIONS.CENTER);
    },

    popupText() {
      return this.getCustomTranslation(this.moduleData, this.buttonPopupModuleFields.POPUP_TEXT);
    },

    popupImageUrl() {
        return this.getCustomTranslation(this.moduleData, this.buttonPopupModuleFields.POPUP_IMAGE_URL, null);
    },
  },

  methods: {
    onButtonClick() {
      this.$root.openInfoModal({
        title: this.popupTitle,
        message: this.popupText,
        messageAlignment: this.popupTextAlignment,
        imageUrl: this.popupImageUrl,
      });
    }
  },
};
</script>

<style scoped></style>
