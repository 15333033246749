<template>
  <div class="box attendee-container" @click="openUserProfile()">
    <div class="profile-container">
      <div class="columns is-mobile is-gapless is-vcentered list-item-container my-0">
        <div class="column is-narrow">
          <profile-picture :picture="picture" :initials="initials" class="is-50x50"></profile-picture>
        </div>
        <div class="column ml-1">
          <div class="title">
            {{ title }}
          </div>
          <div class="subtitle">
            {{ subtitle }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFriendsButton">
      <div @click.stop="addFriend" class="button is-text is-paddingless p-1" :disabled="isFriendOrHasInvitation">
        <accepted-icon class="has-fullheight has-fill-primary" v-if="isFriendOrHasInvitation"></accepted-icon>
        <add-icon class="has-fullheight has-fill-primary" v-else></add-icon>
      </div>
    </div>
    <div class="action-buttons" v-else-if="!isCurrentUser && inboxEnabled">
      <div @click.stop="openInbox" class="button is-text is-paddingless p-1">
        <message-icon class="has-fill-primary"></message-icon>
      </div>
    </div>
  </div>
</template>

<script>
import UserProfileModal from "@/web/components/profile/UserProfileModal";
import MessageIcon from "@/assets/contact/message-icon.svg";
import {Constants} from "@/web/constants";
import {mapActions, mapGetters} from "vuex";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import {fullName, initials, positionAndCompany} from "@/shared/utils";
import AddIcon from "@/assets/icons/add_2.svg";
import AcceptedIcon from "@/assets/icons/accept.svg";

export default {
  name: "AttendeeListItem",

  components: {MessageIcon, ProfilePicture, AddIcon, AcceptedIcon},

  props: {
    user: {
      type: Object,
    },

    isCurrentUser: {
      type: Boolean,
    },

    inboxEnabled: {
      type: Boolean,
    },

    showFriendsButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),
    ...mapActions("friends", ["addToFriends", "removeFromFriends"]),

    openInbox() {
      this.checkIfThreadExists({userId: this.user.user_identity_token}).then(result => {
        if (result.data) {
          const threadId = result.data.id;
          this.openInboxBubbleWithThread(threadId);
          // this.$router.push({ name: Constants.ROUTE_INBOX_THREAD, params: { threadId } });
        } else {
          this.openInboxBubbleWithUser(this.user.user_identity_token);
          // this.$router.push({
          //   name: Constants.ROUTE_INBOX_CREATE_THREAD,
          //   params: {userId: this.user.user_identity_token}
          // });
        }
      });
    },

    openUserProfile() {
      this.$buefy.modal.open({
        parent: this,
        component: UserProfileModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          userId: this.user.user_identity_token,
        },
      });
    },

    addFriend() {
      if (!this.isFriendOrHasInvitation) {
        this.addToFriends(this.user.user_identity_token);
      }
    },
  },

  computed: {
    ...mapGetters("friends", ["hasInvitationWithUser"]),

    Constants: () => Constants,

    title() {
      return fullName(this.user);
    },

    subtitle() {
      return positionAndCompany(this.user);
    },

    initials() {
      return initials(this.user);
    },

    picture() {
      return this.user.picture;
    },

    isFriendOrHasInvitation() {
      return this.user.is_friend || this.hasInvitationWithUser(this.user.user_identity_token);
    },
  },
};
</script>

<style scoped lang="scss">
.attendee-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;

  &:hover {
    cursor: pointer;
    border-color: var(--primary-color-hover);
  }
}

.action-buttons {
  flex: 0 1;
}

.profile-container {
  flex: 1 0;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
</style>
