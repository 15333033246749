<template>
  <article class="module" v-if="questionsData.length">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4" v-if="title">
        {{ title }}
      </h2>

      <div v-for="question in questionsData" :key="question.question" class="faq-item-container">
        <faq-question
          :question="getCustomTranslation(question, faqQuestionFields.QUESTION)"
          :answer="getCustomTranslation(question, faqQuestionFields.ANSWER)"
        ></faq-question>
      </div>
    </div>


  </article>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import FaqQuestion from "./faq/FaqQuestion";

export default {
  name: "FaqComponent",
  mixins: [LpConfigMixin],
  components: { FaqQuestion, Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    faqModuleFields: () => LpConfigConstants.FAQ_MODULE_FIELDS,
    faqQuestionFields: () => LpConfigConstants.FAQ_QUESTION_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    questionsData() {
      return this.getFieldValueOrDefault(this.moduleData, this.faqModuleFields.QUESTIONS_DATA, []);
    },
  },
};
</script>

<style scoped></style>
