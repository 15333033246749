<template>
  <div class="purchase-summary">
    <template v-if="purchaseSummaryRows && purchaseSummaryRows.length && !(isPismEvent || areAllFree)">
      <template>
        <purchase-summary-row v-for="row in purchaseSummaryRows" :key="row.ticket.id" :row-data="row"></purchase-summary-row>
      </template>

      <div class="columns is-gapless is-vcentered is-mobile mb-2">
        <div class="column has-text-weight-bold">
          <p class="is-size-6 title">{{ $t("payment.final_price") }}</p>
        </div>
        <div class="column has-text-weight-bolder is-narrow">
          <p class="is-size-4 has-text-primary">
            <template v-if="finalPriceWithCurrency > 0">
              {{ finalPriceWithCurrency | currency({ locale: "pl-PL", currency: currency }) }}
            </template>
            <template v-else>
              {{ $t(paymentFinalPrice) }}
            </template>
          </p>
        </div>
      </div>
    </template>
    <template v-else-if="(isPismEvent || areAllFree) && purchaseSummaryRows && purchaseSummaryRows.length">
      <div class="subtitle mb-3" v-if="isDolbyEvent && step === 0">
        {{ $t("custom.dolby_custom_ticket_next_step") }}
      </div>
      <div class="subtitle mb-3" v-else>
        {{ $t("ticket.free_ticket_register_summary_text") }}
      </div>
    </template>
    <template v-else>
      <div class="subtitle mb-3">
        {{ $t("payment.summary_empty_shopping_cart") }}
        <div class="subtitle mb-3 mt-1" v-if="isDolbyEvent && step === 0">
          {{ $t("custom.dolby_custom_ticket_next_step") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PurchaseSummaryRow from "@/web/components/purchase/PurchaseSummaryRow";
import Constants from "@/web/constants";
import { getCurrencyMultiplier } from "@/shared/utils";

export default {
  name: "PurchaseSummary",
  components: { PurchaseSummaryRow },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  filters: {
    currency(value, { locale, currency }) {
      const opts = { style: "currency", currency: currency || "PLN", minimumFractionDigits: 2 };
      const formatter = new Intl.NumberFormat(locale, opts);
      const formatted = formatter.format(Number(value));
      return formatted.replace(/,00/, "");
    },
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["eventTicketsVat"]),
    ...mapGetters("payment", ["purchaseSummaryRows", "finalPrice"]),
    ...mapGetters("eventTickets", ["getById", "areAllFree"]),
    ...mapState("payment", ["discount"]),

    isPismEvent() {
      return this.eventId === 1312;
    },

    isDolbyEvent() {
      return this.eventId === 1318;
    },
    
    isVCEvent() {
      return this.eventId === 1604;
    },

    currency() {
      return this.purchaseSummaryRows && this.purchaseSummaryRows.length && this.purchaseSummaryRows[0].ticket.currency;
    },

    Constants: () => Constants,

    finalPriceWithCurrency() {
      return getCurrencyMultiplier(this.currency) * this.finalPrice;
    },

    paymentFinalPrice() {
      if (this.isVCEvent) {
        return "custom.vc_total_price_zero";
      } else {
        return "payment.free";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.purchase-summary {
  max-width: 320px;
  width: 100%;
}

.is-size-4 {
  font-size: 24px !important;
}

.subtitle {
  white-space: pre-line;
}
</style>
