<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color is-2 has-text-centered mb-4">
        {{ title }}
      </h2>
      <div class="has-text-left has-text-weight-semibold description-text">
        <div class="is-whitespaced">
          L'application sera l'outil central de l'organisation de cet évènement. Elle est composée de nombreux modules
          qui vous permettront :
        </div>
        <ul class="mt-1 px-3 has-text-left">
          <li>• d'accéder à l'agenda et interagir durant chaque séance en posant vos questions instantanément ;</li>
          <li>• de visualiser la carte des lieux où les évènements se dérouleront ;</li>
          <li>• de participer à des sondages et quiz durant les ateliers ;</li>
          <li>• de poster vos photos retraçant les temps forts de ce séjour ;</li>
          <li>• de réseauter avec d'autres participants ;</li>
          <li>• d'interagir en direct tout au long du séjour ;</li>
          <li>• de retrouver les synthèses de chaque session ;</li>
          <li>• et bien plus encore !</li>
        </ul>

        <div class="columns is-vcentered is-centered is-mobile mt-2">
          <div class="column is-half is-hidden-mobile">
            <div class="has-text-centered">
              <img :src="mrhMobile1" style="max-height: 420px;"/>
              <img :src="mrhMobile2" style="max-height: 420px;"/>
            </div>
          </div>
          <div class="column is-half-tablet is-full-mobile">
            <div class="has-text-weight-semibold has-text-left mb-4 is-whitespaced">
              <div>
                1. Téléchargez l'application MRH - It Starts With Us depuis le store et ouvrez-la.
              </div>
              <div class="mt-1">
                2. Insérez le code d'accès INTERLAKEN2022 et connectez-vous avec votre mot de passe.
              </div>
              <div class="mt-1">
                3. Et voilà, bienvenue ! Parcourez les différentes fonctionnalités.
              </div>
            </div>

            <div class="columns is-variable is-1 is-mobile is-centered is-vcentered">
              <div class="column is-narrow">
                <figure class="box image is-170x170 px-0 py-0">
                  <img :src="qrCodeImageUrl" alt="qrCode"/>
                </figure>
              </div>
              <div class="column is-narrow-mobile">
                <a :href="appleStoreUrl" class="is-block ml mb-3" target="_blank">
                  <apple-store-button class="app-store-button"></apple-store-button>
                </a>
                <a :href="googlePlayUrl" class="is-block" target="_blank">
                  <google-play-button class="google-play-button"></google-play-button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="is-fullwidth is-flex is-align-items-center is-justify-content-center">-->
        <!--          <div class="columns is-variable is-1 is-mobile is-centered is-vcentered mt-3 mx-auto">-->
        <!--            <div class="column is-narrow">-->
        <!--              <figure class="box image is-170x170 px-0 py-0">-->
        <!--                <img :src="qrCodeImageUrl" alt="qrCode"/>-->
        <!--              </figure>-->
        <!--            </div>-->
        <!--            <div class="column is-narrow-mobile">-->
        <!--              <a :href="appleStoreUrl" class="is-block ml mb-3" target="_blank">-->
        <!--                <apple-store-button class="app-store-button"></apple-store-button>-->
        <!--              </a>-->
        <!--              <a :href="googlePlayUrl" class="is-block" target="_blank">-->
        <!--                <google-play-button class="google-play-button"></google-play-button>-->
        <!--              </a>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </article>
</template>

<script>
import Separator from "@/web/components/shared/Separator";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import {mapGetters, mapState} from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import AppleStoreButton from "@/web/components/mobileapp/buttons/AppleStoreButton";
import GooglePlayButton from "@/web/components/mobileapp/buttons/GooglePlayButton";
import MrhMobile1 from "@/assets/custom/mrh_mobile_1.png";
import MrhMobile2 from "@/assets/custom/mrh_mobile_2.png";

export default {
  name: "MRHCustomComponent",

  components: {
    Separator,
    AppleStoreButton,
    GooglePlayButton,
  },
  mixins: [LpConfigMixin],

  props: ["moduleData"],

  computed: {
    ...mapGetters("currentUser", ["uuid"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapState(["event"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE, null);
    },

    mrhMobile1() {
      return MrhMobile1;
    },

    mrhMobile2() {
      return MrhMobile2;
    },

    qrCodeImageUrl() {
      const qrcode = this.event.qrcode;
      return `https://qr.meetingapplication.com/?qr_url=${qrcode}`;
    },

    appleStoreUrl() {
      const appleStoreId = this.event.apple_shop_app_id;
      return `https://apps.apple.com/us/app/ma-2-0/id${appleStoreId}`;
    },

    googlePlayUrl() {
      const googlePlayId = this.event.google_shop_app_id;
      return `https://play.google.com/store/apps/details?id=${googlePlayId}`;
    },
  }
};
</script>

<style scoped>
ul {
  margin-left: 16px;
}

.description-text {
  white-space: pre-wrap;
  color: var(--global-description-text-color);
}

.is-whitespaced {
  white-space: pre-line;
}
</style>
