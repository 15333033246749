<template>
  <div>
    <div class="purchase-summary-row-container">
      <div class="purchase-summary-row-title">
        {{ title }}
      </div>
      <div class="purchase-summary-row-price" >
        <template v-if="preTaxPrice > 0">
          {{ preTaxPrice | currency({ locale: "pl-PL", currency: currency }) }}
        </template>
        <template v-else>
          {{ $t(paymentFinalPrice) }}
        </template>
      </div>
    </div>
    <div class="purchase-summary-row-container" v-if="tax > 0">
      <div class="purchase-summary-row-title">
        {{ $t("payment.vat") }}
      </div>
      <div class="purchase-summary-row-price">
        {{ tax | currency({ locale: "pl-PL", currency: currency }) }}
      </div>
    </div>
    <div class="purchase-summary-row-container" v-if="bulkDiscount">
      <div class="purchase-summary-row-title is-discount">
        {{ bulkDiscountTitle }}
      </div>
      <div class="purchase-summary-row-price is-discount">
        {{ bulkDiscountPriceWithCurrency | currency({ locale: "pl-PL", currency: currency }) }}
      </div>
    </div>
    <div class="purchase-summary-row-container" v-if="discount">
      <div class="purchase-summary-row-title is-discount">
        {{ discountTitle }}
      </div>
      <div class="purchase-summary-row-price is-discount">
        {{ discountPriceWithCurrency | currency({ locale: "pl-PL", currency: currency }) }}
      </div>
    </div>
    <div class="purchase-summary-row-container" v-if="discountedTicketsQuantityTitle">
      <div class="purchase-summary-row-title is-discount">
        {{ discountedTicketsQuantityTitle }}
      </div>
    </div>
    <div class="purchase-summary-row-container" v-if="marketingDiscount">
      <div class="purchase-summary-row-title is-marketing-discount">
        {{ marketingDiscountTitle }}
      </div>
      <div class="purchase-summary-row-price is-marketing-discount">
        {{ marketingDiscountPriceWithCurrency | currency({ locale: "pl-PL", currency: currency }) }}
      </div>
    </div>
    <template v-if="eventAddons">
      <div v-for="eventAddonRow in eventAddons" :key="`addon-row-${eventAddonRow.addon_model.id}`">
        <div class="purchase-summary-row-container">
          <div class="purchase-summary-row-title">
            {{ `${eventAddonRow.addon_quantity} x ${eventAddonRow.addon_model.name}` }}
          </div>
          <div class="purchase-summary-row-price">
            {{ getPreTaxPriceForAddon(eventAddonRow) | currency({ locale: "pl-PL", currency: currency }) }}
          </div>
        </div>
        <div class="purchase-summary-row-container">
          <div class="purchase-summary-row-title">
            {{ $t("payment.vat") }}
          </div>
          <div class="purchase-summary-row-price">
            {{ getTaxForAddon(eventAddonRow) | currency({ locale: "pl-PL", currency: currency }) }}
          </div>
        </div>
      </div>
    </template>

    <hr class="my-1" />
  </div>
</template>

<script>
import PaymentConstants from "@/shared/constants/payment-constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import { mapGetters, mapState } from "vuex";
import { getCurrencyMultiplier, getDiscountAmountForSingleTicket, getMarketingDiscountAmount, getBulkDiscountAmount } from "@/shared/utils";

export default {
  name: "PurchaseSummaryRow",
  mixins: [LpConfigMixin],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },

  filters: {
    currency(value, { locale, currency }) {
      const opts = { style: "currency", currency: currency || "PLN", minimumFractionDigits: 2 };
      const formatter = new Intl.NumberFormat(locale, opts);
      const formatted = formatter.format(Number(value));
      return formatted.replace(/,00/, "");
    },
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["lpSettings", "eventTicketsVat"]),

    paymentConfig() {
      return this.getFieldValueOrDefault(this.lpSettings, LpConfigConstants.ROOT_FIELDS.PAYMENT, {});
    },

    ticket() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.TICKET];
    },

    ticketsQuantity() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.QUANTITY];
    },

    eventAddons() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_ROW];
    },

    discount() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNT];
    },

    discountedTicketsQuantity() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.DISCOUNTED_TICKETS_QUANTITY] || 0;
    },

    bulkDiscount() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.BULK_DISCOUNT];
    },

    bulkDiscountTitle() {
      return this.bulkDiscount && `${this.$t("payment.bulk_discount")}:`;
    },

    isVCEvent() {
      return this.eventId === 1604;
    },

    bulkDiscountPriceWithCurrency() {
      return (
        getBulkDiscountAmount(
          this.discount,
          this.marketingDiscount,
          this.bulkDiscount,
          this.ticketPrice,
          this.discountedTicketsQuantity,
          this.ticketsQuantity,
        ) *
        getCurrencyMultiplier(this.currency) *
        -1
      );
    },

    paymentFinalPrice() {
      if (this.isVCEvent) {
        return "custom.vc_total_price_zero";
      } else {
        return "payment.free";
      }
    },

    marketingDiscount() {
      return this.rowData[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.MARKETING_DISCOUNT];
    },

    title() {
      if (this.ticket.id === 518) {
        return `${this.ticketsQuantity / 3} x ${this.ticket.name}`;
      }
      return `${this.ticketsQuantity} x ${this.ticket.name}`;
    },

    discountTitle() {
      return this.discount && `${this.$t("payment.discount_code")}: ${this.discount.discount_code}`;
    },

    discountedTicketsQuantityTitle() {
      return (
        this.discount &&
        this.discountedTicketsQuantity !== this.ticketsQuantity &&
        this.$t("payment.discounted_tickets_quantity", [this.discountedTicketsQuantity])
      );
    },

    ticketPrice() {
      return this.ticket.price;
    },

    ticketPriceWithCurrency() {
      return this.ticket.price * getCurrencyMultiplier(this.currency);
    },

    preTaxPrice() {
      const factor = 1.0 + this.eventTicketsVat;
      return (this.ticketsQuantity * this.ticketPriceWithCurrency) / factor;
    },

    tax() {
      return this.ticketsQuantity * this.ticketPriceWithCurrency - this.preTaxPrice;
    },

    discountAmountForSingleTicket() {
      return getDiscountAmountForSingleTicket(this.discount, this.ticketPrice);
    },

    discountPriceWithCurrency() {
      if (this.discountAmountForSingleTicket) {
        return this.discountAmountForSingleTicket * this.discountedTicketsQuantity * getCurrencyMultiplier(this.currency) * -1;
      }
      return null;
    },

    currency() {
      return this.ticket.currency;
    },

    marketingDiscountTitle() {
      return this.marketingDiscount && this.$t("payment.marketing_discount");
    },

    marketingDiscountPriceWithCurrency() {
      return (
        getMarketingDiscountAmount(
          this.discount,
          this.marketingDiscount,
          this.ticketPrice,
          this.discountedTicketsQuantity,
          this.ticketsQuantity
        ) *
        getCurrencyMultiplier(this.currency) *
        -1
      );
    },
  },

  methods: {
    getPreTaxPriceForAddon(eventAddonRow) {
      const factor = 1.0 + this.eventTicketsVat;
      if(eventAddonRow['force-zero-price'] === true) {
        return 0;
      }
      return (
        (eventAddonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_QUANTITY] *
          this.getAddonPriceWithCurrency(eventAddonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_MODEL])) /
        factor
      );
    },

    getAddonPriceWithCurrency(eventAddonModel) {
      return eventAddonModel.price * getCurrencyMultiplier(this.currency);
    },

    getTaxForAddon(eventAddonRow) {
      if(eventAddonRow['force-zero-price'] === true) {
        return 0;
      }
      return (
        eventAddonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_QUANTITY] *
          this.getAddonPriceWithCurrency(eventAddonRow[PaymentConstants.PURCHASE_SUMMARY_ROW_FIELDS.ADDON_MODEL]) -
        this.getPreTaxPriceForAddon(eventAddonRow)
      );
    },
  },
};
</script>

<style scoped></style>
